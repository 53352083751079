<script>

</script>

<footer>
    <nav aria-labelledby="footer-navigation">
        <ul>
            <li>
                <a href="/legal/notice">Legal Notice</a>
            </li>
            <li>
                <a href="/legal/privacy">Privacy Policy</a>
            </li>
            <li>
                <a href="/legal/terms">Terms of Service</a>
            </li>
        </ul>
    </nav>
</footer>

<style>

    footer {
        padding: 2rem var(--page-padding-inline);
    }

    nav {
        display: flex;
        justify-content: center;
    }

    ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    li + li {
        margin-left: .6rem;
        padding-left: .6rem;
        border-left: 1px solid var(--color-main);
    }

    a {
        text-decoration: none;
        color: var(--color-main);
        font-weight: var(--font-emph);
        font-size: .8rem;
    }



</style>